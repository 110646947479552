

.opt-in-container {
 
    font-weight: 400;
    padding-top: 10px;
    color: #ffffff; /* Make text within the container white */
}

.opt-in-form {
    margin-top: 20px;

}

.submit-btn {
    padding: 8px 16px;
    background-color: #faf9f6; 
    border-radius: 6px;
    cursor: pointer;
    font-weight: 400;
    font-size: 1.2rem;
    color: #000; /* Change button text to white */
    outline: none; /* Removes the default focus outline */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover and focus states */
}
  
.submit-btn:hover,
.submit-btn:focus {
    background-color: #d5d5d5; /* Slightly darker grey on hover/focus */
    color: #FFFFFF; /* Keeps text white on hover/focus for better visibility */
}

.input-field-OI {
    border: none;
    outline: none;
    -webkit-appearance: none; /* This line disables default browser styling */
    margin-bottom: 20px;
    width: 100%;
    background-color: transparent;
    border-radius: 0; /* Explicitly remove border-radius */
    font-weight: bolder;
}

.input-field-OI:focus {
    outline: none;
}

.input-field-OI::placeholder {
    color: #989898;
    opacity: 1; /* Firefox */
    font-weight: bolder;
}

.name-fields-container {
    display: flex;
    justify-content: space-between;
    gap: 30px; /* Adjust the gap between the fields */
    margin-right: 15px;
    margin-bottom: 15px;
    margin-top: 40px;
  }
  
  .name-field {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Aligns text to bottom */
}

.input-field-OI,
.phone-input .PhoneInputInput {
    border: none;
    outline: none;
    -webkit-appearance: none;
    border-bottom: 1px solid #d0d0d0ad;
    width: 100%;
    background-color: transparent;
    border-radius: 0;
    font-weight: bold;
    color: #ffffff; /* Ensure text color is black */
    font-size: large;
}

.input-field-OI::placeholder,
.phone-input .PhoneInputInput::placeholder {
    color: #989898;
    font-weight: 200;
}

.phone-input-container-OI, .verification-code-container-OI {
  display: flex;
  justify-content: space-between;
  gap: 30px; /* Adjust as necessary */
  margin: 0 0px; /* This assumes you want to align with the name fields' margins */
  margin-top: 40px;
}

.phone-input .PhoneInputInput {
    border: none !important;
    /* Other styles to match your design */
  }