.invited-section {
   margin-bottom: 100px;
   margin-left: 10px;
  }
  
  .avatar-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates three columns */
    gap: 10px; /* Adjust the gap size as needed */
    margin-left: -10px;
  }
  
  .avatar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .avatar-icon {
    background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.608) !important;
  width: 40px !important;
  height: 40px !important;
  font-size: 25px !important;
  color: rgb(203, 203, 203);
  }
  
  .avatar-name, .avatar-date {
    text-align: center; /* Center the text below the avatar */
    margin-top: 9px;
    font-size: small;
    color: rgb(141, 141, 141);
  }
  
  .rsvp-list ul {
    list-style-type: none; /* Removes bullet points */
    padding: 0; /* Removes default padding */
    margin-left: 10px;
  }
  
  .rsvp-list li {
    margin-bottom: 12px; /* Optional: Adds a little space between list items */
  }

  .rsvp-list {
    margin-left: 10px;
  }