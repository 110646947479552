.invitesPage {
    padding: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
    /* Ensure the invitesPage does not have any inherent width restrictions */
    max-width: 100%;
    overflow: hidden; /* Prevents horizontal scrollbar on the page itself */
  }
  
  .centerButton {
    display: flex;
    justify-content: center;
    width: 100%;
}

.shareYourLoop {
    
    font-size: 22px !important;
    background-color: transparent;
    border-color: rgb(0, 0, 0);
    color: #ffffff !important;
   padding: 18px !important;
   text-transform: none !important; 
   margin-top: -10px !important;
  }
  
  
  .invitesSection, .circlesSection {
    margin-bottom: 20px;
  }

  .invitesSection h2, .circlesSection h2 {
    color: rgb(255, 255, 255);
    padding: 10px;
   
  }

.invitePlaceholder, .addGroupPlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Arbitrary height */
    border: 2px dashed grey;
}

.invitePlaceholder::before, .addGroupPlaceholder div {
    content: "+";
    font-size: 24px;
    color: grey;
}

.eventCardPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed grey;
  height: 55vw; /* Responsive height based on viewport width */
  width: 55vw; /* Responsive width */
  max-height: 210px; /* Max height to prevent too large placeholders */
  max-width: 210px; /* Max width to prevent too large placeholders */
  margin-bottom: 20px;
  flex: 0 0 auto; /* Prevents cards from shrinking or growing */
}

@media (min-width: 600px) {
  .eventCardPlaceholder {
    height: 30vw; /* Adjust height for larger screens */
    width: 30vw; /* Adjust width for larger screens */
    max-height: 210px; /* Keep max dimensions to prevent the card from getting too large */
    max-width: 210px;
  }
}


.eventCardPlaceholder span {
  font-size: 2em;
  color: grey;
}

  

  .eventsContainer {
    display: flex; /* Align children horizontally */
    overflow-x: auto; /* Enable horizontal scroll */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
    gap: 20px; /* Space between cards */
    padding-bottom: 20px; /* Prevents cutting off shadows or effects at the bottom */
  }
  
  .event-card {
    flex: 0 0 auto; /* Don't grow, don't shrink, and stay based on width */
    /* Keep your other styles as-is */
  }

  
  .plusIcon {
    font-size: 40px; /* Size of the plus icon */
    color: #faf9f6; /* Color of the plus icon */
  }
 

  .groupsContainer {
    display: flex; /* Align children horizontally */
    overflow-x: auto; /* Enable horizontal scroll */
    gap: 20px; /* Space between icons */
    padding: 0px 0; /* Padding on top and bottom */
    margin: 0 0px; /* Horizontal margin for the container */
    margin-left: 10px;
  }
  
  .groupIcon {
    width: 72px; /* Width of the group icon */
    height: 72px; /* Height of the group icon */
    border-radius: 50%; /* Circular border */
    background-color: rgba(0, 68, 255, 0); /* White background for group icons */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 47px; /* Larger emoji */
    border: .8px solid #faf9f6c3; /* *rgba(255, 255, 255, 0.798)
    margin-right: 10px; /* Space between icons */
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Shadow for depth */
    flex-shrink: 0; /* Prevents the icon from shrinking */
    margin-bottom: 20px;
  }
  
  .addGroupPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 60px; /* Size of the add icon */
    height: 60px; /* Size of the add icon */
    border-radius: 50%; /* Circular border */
    background-color: transparent; /* White background for add icon */
    border: 1px solid grey; /* Blue border for add icon */
    font-size: 24px; /* Size of the plus icon */
    color: #faf9f6; /* Color of the plus icon */
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Shadow for depth */
  }
  
  /* Hide the scrollbar but allow scrolling */
  .eventsContainer::-webkit-scrollbar, .groupsContainer::-webkit-scrollbar {
    display: none;
  }
  
  .groupsContainer {
    -ms-overflow-style: none; /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */

  }

  .circlesSectionTitleContainer {
    display: flex; /* Use flexbox to layout children horizontally */
    align-items: center; /* Align children vertically in the center */
    justify-content: space-between; /* Space out children: title on the left, button on the right */
    padding: 0 16px; /* Add some padding on the sides if needed */
  }
  
  .addCircleButton {
    width: 35px; /* Diameter of the circle */
    height: 35px; /* Diameter of the circle */
    border-radius: 50%; /* Makes the button circular */
    background-color: #454545ce; /* Grey background */
    color: rgb(243, 240, 240); /* Color of the plus sign */
    border: none; /* Removes the default border */
    font-size: 18px; /* Size of the plus sign */
    cursor: pointer; /* Changes the cursor to pointer when hovered */
    display: flex; /* Use flexbox for centering */
    align-items: center; /* Centers the plus sign vertically */
    justify-content: center; /* Centers the plus sign horizontally */
    outline: none; /* Removes the outline */
    margin-left: auto; /* Pushes the button to the right */
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;
    justify-content: center;
   
  }
  
  .modal-content {
    background-color: #faf9f6; /* Blue background */
    color: black; /* White text */
    padding: 20px;
    width: 90%; /* Or any other width */
    border-radius: 10px 10px 0 0;
    position: relative; /* For absolute positioning of children */
    text-align: center; /* Center text */
    margin-bottom: 15px;
    transform: translateY(30px); /* Moves modal content down */
    margin-top: -20px;
    font-weight: 400;
  }


  /* Close button */
  .modal-close {
    position: absolute;
    top: -40px;
    left: -165px;
    cursor: pointer;
    border: none;
    background: none;
    color: #000000 !important;
    font-size: 24px;
  }
  
  /* Submit button */
  .modal-submit {
    position: absolute;
    top: -15px;
    right: 0px;
    cursor: pointer;
    font-size: 19px;
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Makes the div circular */
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    color: #ffffff;
    background-color: #007aff;
  }
  
  /* Emoji picker toggle */
  .modal-add-icon {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    width: 60px; /* Diameter of the circle */
    height: 60px; /* Diameter of the circle */
    border-radius: 50%; /* Makes the div circular */
    background-color: rgba(255, 255, 255, 0); /* White background for the circle */
    margin: auto; /* Centers the circle in the available space */
    font-size: 16px; /* Adjust the size of your text as needed */
    color: #000000; /* Text color */
    border: 1px solid #000000; /* Blue border color to match your modal */
    margin-top: -40px;
    
  }
  
  
  /* Input field */
  .modal-input {
    margin: 20px auto;
    display: block;
    width: 50%; 
    text-align: center; /* Center text */
  }
  
  /* Instructional text */
  .modal-instruction {
    margin-top: 20px;
    font-weight: 300;
    margin-bottom: 20px;
    font-weight: 400;
  }
  
  /* Hide picker by default */
  .emoji-picker-react {
    display: none;
  }
  
  /* Show picker when state is true */
  .show-picker .emoji-picker-react {
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;

  }

