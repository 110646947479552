.event-details-container {
    display: flex;
    justify-content: left;
    padding: 20px;
    margin-top: -20px;
    text-align: left; 
}

.event-bubble {
    max-width: 250px;
    background-color: #007aff; /* iMessage bubble color */
    color: white;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-left: -20px; 
    margin-right: 60px;
    overflow-wrap: break-word;
    padding-right: 14px;
}

.event-title {
    font-weight: bold;
    font-size: 1.5em;
    margin-left: 10px;
}

.event-description {
    margin-top: 0px;
   
}

.event-datetime-location p {
    margin: 0; /* Removes default margin */
    padding: 0; /* Removes default padding */
    line-height: 1.2; /* Adjusts line spacing to be tighter */
}

/* Existing CSS for event-bubble and other classes remain the same */

