/* GroupsPage.css */
.groups-container {
    padding: 20px;
}

.groups-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
}

.groups-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.group-item {
    /* Light grey background */
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.group-link {
    text-decoration: none;
    font-size: 1.25rem;
    font-family: 'roboto', monospace;
    font-weight: 500;
    display: block;
}
