.event-details-page {
    display: flex;
    flex-direction: column;
    height: 100%; /* Use full height to make footer sticky */
}

.footer-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #0d0d0d; /* Dark background */
    display: flex;
    justify-content: space-around; /* Evenly space the buttons */
    padding: 10px 0; /* Some padding */
    box-shadow: 0 -2px 5px rgba(0,0,0,0.1); /* Add shadow for depth */
    border-top: 0.1px solid #dcdcdc50;
}

.footer-button {
    flex-grow: 1;
    margin: 0 10px; /* Space out buttons */
    padding: 3px 0; /* Reduce padding to decrease space */
    background-color: #007bff00; /* Transparent background */
    color: white;
    border: none;
    border-radius: 5px; /* Slightly rounded corners */
    cursor: pointer;
    text-align: center; /* Center text and icon */
}

.footer-button .material-icons {
    margin-bottom: 4px; /* Reduce space between icon and text */
    font-size: 24px; /* Adjust icon size as needed */
}

.footer-button span {
    display: block; /* Ensure text aligns under the icon */
    margin-top: -6px; /* Reduce space above the text */
    font-size: 14px; /* Adjust text size as needed */
}
