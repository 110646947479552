.formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: 'transparent';
    padding-top: 15px;
  }
  
  .formField {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 9px;
    margin-right: 10px;
  
  }
  
  .formLabel {
  font-size: 1.2rem;
  font-weight: 300;
  color: white;
  display: flex;
  align-items: center;
  gap: 0px; /* Adjust the space between the label text and the hint text */
}

  .hintText {
    font-size: 0.8rem; /* Smaller text for the hint */
    color: #aaa; /* Light grey color for the hint */
    font-weight: 400;
    margin-left: 4px; /* Small margin for spacing */
  }
  
  .formInput,
  .formTextarea {
    font-size: 1rem; /* Comfortable reading size */
    padding: 12px; /* Larger padding for easier interaction */
    border-radius: 8px; /* Aesthetic choice, can be set to 0 for no rounded corners */
    background-color: rgba(39, 39, 39, 0.731);
    border-color: #0000007c;
    color: white;
    outline: none;
    
  }
  
  .formTextarea {
    min-height: 100px; /* Sufficient space for typing longer text */
    resize: 'none';
    overflow: 'hidden';
  }

  .formInput:focus,
.formTextarea:focus {
  border-color: #1DA1F2; /* Twitter's brand color for focus */
}

  .formRow {
    display: flex;
    gap: 50px; /* Adjust gap as necessary */
  }

  
  
  .submitButton {
    padding: 8px 16px;
    background-color: #2f83f1; /* Light grey background */
    border: 1.5px solid #000000; /* Black border */
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.4rem;
    outline: none; /* Removes the default focus outline */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover and focus states */
  }
  

  .messageCenter {
    text-align: center; /* Centers the hint text */
  }
  /* Example media query for larger screens */
  @media (min-width: 768px) {
    .formLabel {
      font-size: 1.5rem;
    }
  
    .formInput,
    .formTextarea {
      font-size: 1.25rem;
    }
  
    .submitButton {
      font-size: 1.5rem;
      padding: 15px 25px;
    }

    .formRow {
      flex-direction: column;
    }
  }
  