.groupDetail {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 30px;
  }

 
  
  .groupDetail .MuiSvgIcon-root {
    width: 25px; /* Adjust the width of the icon */
    height: 25px; /* Adjust the height of the icon */
  }
  
  .groupDetail .MuiCheckbox-root, .groupDetail .MuiCheckbox-colorSecondary.Mui-checked {
    color: rgb(184, 184, 184); /* Sets the color of unchecked checkboxes to white */
  }
  
  /* Custom checkbox border for unchecked state */
  .groupDetail .MuiCheckbox-root .MuiSvgIcon-root {
    border: 2px solid rgb(0, 0, 0); /* Sets the border color of unchecked checkboxes */
    
  }
  
  /* This targets only the checked state of the checkbox */
  .groupDetail .MuiCheckbox-colorSecondary.Mui-checked .MuiSvgIcon-root {
    border: none; /* Removes the border for checked state if not needed */
  }
  
  
  .groupHeader {
    padding: 16px;
    position: relative; /* For absolute positioning of the CloseIcon */
  }
  
  .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 16px;
    left: 0px; /* Move close icon further to the left */
  }
  
  .groupInfoText {
    font-size: small;
    color: #888;
    margin-bottom: 20px; /* Adjust as needed to create space between text and group info */
    text-align: center;
    margin-top: 0px;
    margin-right: -8px;
    margin-left: 7px;
    font-weight: 300;
  }
  
  .groupInfo {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px; /* Adjust if needed */
  }
  
  
  .groupIcon {
    /* Add your styles for the group icon, e.g., border, background, etc. */
  }
  
  .groupName {
    font-weight: 400;/* Add your styles for the group name */
  }
  
  .friendList, .addFriendsList {
    padding: 16px;
    overflow-y: auto;
    font-weight: 400;
  }
  
  h4 {
    margin-top: 0;
  }
  
  .stickyFooter {
    position: fixed;
    bottom: 0;
    left: 0; /* Ensure it starts from the very left */
    right: 0; /* Ensure it stretches to the very right */
    background: #0d0d0d;
    padding: 12px;
    box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
    display: flex; /* Makes its children (the button) able to fill the footer */
    justify-content: center; /* Center the button horizontally */
    border-top: 0.1px solid #dcdcdc38; /* Thin grey border */
  }
  
  .stickyFooter Button {
    width: 90%; /* Makes the button take up nearly the entire width of its parent */
    max-width: 600px; /* Optional: Ensures button doesn't get too wide on larger screens */
    padding: 10px 0; 
    border-radius: 8px;
  }
  
  .groupInfoContainer {
    text-align: left; /* Aligns the content to the left */
  }
  
  .friendList ul, .addFriendsList ul {
    list-style-type: none; /* Removes bullet points */
    padding: 0; /* Removes default padding */
  }
  
  .friendList li, .addFriendsList li {
    display: flex;
    justify-content: space-between; /* Aligns content and checkbox */
    align-items: center; /* Centers items vertically */
    margin-bottom: 8px; /* Adds some space between list items */
  }
  
  .contactItem {
    list-style-type: none; /* Remove bullet points */
    display: flex;
    justify-content: space-between; /* Aligns the contact name and checkbox on opposite ends */
    align-items: center; /* Keeps items vertically centered */
    padding: 8px 0; /* Add some vertical padding for better spacing */
  }
  
  .contactCheckbox {
    margin-left: auto; /* Pushes the checkbox to the right */

  }


 