/* ReviewSendMessage.css */
.review-send-message {
  padding: 20px;
  margin-bottom: 30px;
}

.message-bubble {
  max-width: 600px;
  background-color: #60606082; /* Light grey */
  padding: 15px;
  border-radius: 20px;
  margin-bottom: 20px;
  color: white;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-left: -20px; 
  margin-right: 50px;
  overflow-wrap: break-word;
  padding-right: 14px;
}

.invitees-section {
  margin-bottom: 20px;
}

.rsm-sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #00000000;
  display: flex;
  justify-content: stretch; /* Ensures that children (buttons) can stretch */
  align-items: stretch; /* Makes buttons stretch to the full height of the footer */
  margin: 0; /* No margin around the footer */
  padding: 0; /* Remove any default padding */
}



.invitee-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Adds space between invitee items */
    margin-left: -7px;

  }
  
  .group-item {
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    margin-left: -8px;
  }
  
  .group-avatar {
    margin-right: 10px; /* Adds space between the avatar and the group name */
    margin-left: -5px;
  }
  
  .group-name, .contact-item {
    margin-left: 10px; /* Adds space between items for readability */
  }
  
  .groups, .individuals {
    margin-bottom: 10px; /* Additional space between groups and individual contacts */
    
  }
  
  .groups {
    margin-top: -10px;
  }

  .rsm-h1 {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 30px;
    margin-left: -5px;
  }

  .message-bubble .rsm-event-details p {
    margin: 0; /* Removes default margin */
    padding: 0; /* Adjust or remove padding as desired */
  }
  
  /* Ensure there's a little space above and below the event details */
  .message-bubble .rsm-event-details {
    margin: 8px 0;
  }
  
  .rsm-button {
    flex: 1; /* Each button will take half the space */
    border: none; /* No border */
    margin: 0; /* No margin between buttons */
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    font-weight: bold; /* Bold font */
    font-size: 18px; /* Adjust the font size as needed */
    transition: background-color 0.3s; /* Smooth transition for hover effects */
    -webkit-appearance: none; /* Remove default styling on iOS */
    -moz-appearance: none; /* Remove default styling on Firefox */
    appearance: none; /* Remove default styling */
    height: 100%; /* Button takes the full height of the footer */
   
  }
  
  /* Styles for the 'Cancel' button */
  .rsm-cancel-button {
    background-color: #8a8989; /* Light grey background */
    color: #ffffff; /* Dark text */
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  /* Styles for the 'Send' button */
  .rsm-send-button {
    background-color: #007aff; /* Dark purple background from the screenshot */
    color: #ffffff;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  .rsm-sticky-footer {
    height: 50px; /* Increase the height of the footer */
  }
  
  .rsm-button {
    height: 100%; /* Ensure button height is 100% of the footer's height */
  }
  
  /* Ensure buttons meet exactly in the middle without any space */
  .rsm-button:not(:last-child) {
    border-right: 0px solid #007aff; /* Add a border to the right of the first button */
  }