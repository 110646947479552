.sr-selectRecipients {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}

.sr-header {
  display: flex;
  align-items: center;
  padding: 16px;
  position: relative;
  margin-bottom: -10px;
}

.sr-closeIcon {
  cursor: pointer;
  position: absolute;
  left: 16px;
  top: 16px;
}

h1 {
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 30px;
  font-size: x-large;
 
}


.sr-selectionContainer {
  flex-grow: 1;
  overflow: auto;
  padding: 16px;
}

.sr-listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.sr-groupInfo {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust this value to give space between the icon and the name */
  font-size: larger;
}

.sr-footer {
  display: flex;
  justify-content: space-between; /* Adjust content to each side */
  align-items: center;
  position: sticky;
  bottom: 0;
  background-color: #0d0d0d;
  padding: 12px;
  text-align: right;
  margin: 0 -16px; /* Adjust margin to match your site's specific padding or layout */
  padding: 12px 24px; /* Add padding here to compensate for the negative margin */
  z-index: 3;
}

.sr-footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top: 0.1px solid #dcdcdc50;
  width: 100%;
}

.sr-footerButton {
  padding: 13px 30px;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: large;
}

.sr-selectedCount {
  color: white; /* Adjust text color as needed */
  margin-left: 12px; /* Give some space on the left */
}

.sr-customAvatar {
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.608) !important;
  width: 50px !important;
  height: 50px !important;
  font-size: 30px !important;
}



.sr-selectRecipients .MuiCheckbox-root {
  color: rgba(211, 211, 211, 0.67); /* Sets the color of the checkbox when it is not checked */
}

/* Target the checkbox when it's checked to apply the border */
.sr-selectRecipients .MuiCheckbox-root.Mui-checked {
  border: none; /* You can remove any border if needed when checked */
}

