/* Login.css */
.login-container {
    padding: 30px; /* Increase padding around the elements */
  }

 
  .login-container h1 {
    margin-top: 20px; /* Increase space above the title */
    margin-bottom: 30px; /* Increase space below the title */
    font-size: 2.5rem;
  }

  .phone-input-container {
    margin-bottom: 20px; /* Add some space below the phone input */
    margin-top: 20px; /* Increase space above the phone input container */
  }
  
  .phone-input-container .PhoneInputCountrySelect, 
  .phone-input-container .PhoneInputInput {
    height: 44px; /* Increase height of phone input and flag */
    font-size: 18px; /* Increase font size in the input field */
  }
  
  .button-container button, .form-submit-button {
    width: 100%; /* Make button take full container width */
    padding: 15px; /* Increase padding for larger touch area */
    margin-top: 60px; /* Add some space above the button */
    font-size: 18px; /* Increase font size of button text */
    border: none; /* Remove default border */
    border-radius: 5px; /* Optional: round the corners */
    background-color: #faf9f6; /* Example blue background */
    color: rgb(7, 7, 7); /* White text color */
    cursor: pointer; /* Cursor changes to pointer to indicate button */
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center buttons horizontally */
  }
  
 
  
  .PhoneInputInput {
    background-color: transparent !important; /* Use !important to override any existing styles */
    color: #ffffff; /* Change text color if needed for visibility */
    border: none !important;
  border-bottom: 1.5px solid #faf9f6 !important; /* Example border, adjust as needed */
  border-radius: 0 !important;
  }

  .PhoneInputInput:focus {
    outline: none !important;
    border-bottom: 1.5px solid #faf9f6 !important; /* Example focus border, adjust as needed */
  }

  .verification-code-input {
    width: 100%; /* Make the input take full width of its container */
    height: 50px; /* Increase the height of the input field */
    font-size: 20px; /* Increase the font size inside the input field */
    background-color: transparent; /* Make the background transparent */
    border: none; /* Remove all borders */
    border-bottom: 1.5px solid #faf9f6; /* Add a bottom border */
    margin-top: 20px; /* Increase space above the input field */
    color: #ffffff; /* Change the text color */
    border-radius: 0;
    margin-bottom: 10px;
  }
  
  .verification-code-input:focus {
    outline: none; /* Remove the outline on focus */
    border-bottom: 1.5px solid #faf9f6; /* Change the bottom border color on focus, adjust as needed */
  }

  .invite-code-form {
    
   
  }

  .invite-code-form input {
    padding: 10px;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border-radius: 10px;
    box-sizing: border-box; /* Include padding in height calculation */
    height: 42px; /* Match the height of the button */
    width: 100%;
    margin-top: 20px;
    margin-bottom: -10px;
  }