.message-rsvps-container {
    padding: 20px;
    margin-bottom: 30px;
    display: flex;
  flex-direction: column;
  }

  .message-rsvps-container h1 {
    text-align: left; /* Aligns the heading text to the left */
    margin-bottom: 20px; /* Increase space between the heading and the message bubble */
    margin-left: 15px;
    margin-top: 20px;
  }
  
  .rsvpmessage-bubble {
    background-color: #60606082; /* This is the grey color of iMessage bubbles */
    border-radius: 20px;
    padding: 16px;
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
    margin-right: 50px;

  }

  .h1 {
    margin-bottom: 30px;
  }
  
  .rsvpmessage-bubble textarea {
    background: transparent;
    border: none;
    outline: none;
    resize: none;
    font-size: 16px;
    margin: 10px 0;
    color: #ffffff;
  }
  
  .update-announcement,
  .nvmd-instruction {
    color: #ffffff;
    font-size: 16px;
  }
  
  .update-announcement {
    margin-top: 0px;
  }

  .nvmd-instruction {
    margin-bottom: 0px;
  }
  /* Additional styles for buttons and other elements may be necessary */
  
  .sent-message-container {
    margin-bottom: 20px;
    width: 100%; /* Ensures the container takes the full width */
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  }
  
  .sent-date {
    color: #ffffff;
    text-align: left;
    margin-bottom: 30px;
    margin-top: 3px;
    font-size: small;
    margin-left: 20px;
  }
  
  .rsvpmessage-bubble.sent {
    background-color: #007aff; /* Light blue like sent iMessage bubbles */
    margin-top: 0px;
    margin-bottom: 0px;
    align-self: flex-end;
    margin-right: -10px;
    margin-left: 70px;
  }

  .sent-message {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: -5px;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 2s linear infinite;
  }
  