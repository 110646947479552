/* src/Homepage.css */
.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    color: #fff; /* White text */
    font-family: 'Arial', sans-serif; /* Use a sans-serif font */
    margin-top: 100px;
    margin-right: 10px;
  }
  
  h1 {
    font-size: 45px; /* Adjust the size as needed */
    text-align: left;
    margin-bottom: 40px;
  }
  
  .subheading-hp {
    text-align: left;
    font-size: 25px; 
  }

  
  .content {
    text-align: center;
  }
  
  .join-form {
    display: flex; /* Align the button and input side by side */
    align-items: center; /* Vertical alignment */
    margin-top: 40px;
  }
  
  .join-form input {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border-radius: 10px;
    box-sizing: border-box; /* Include padding in height calculation */
    height: 42px; /* Match the height of the button */
  }
  
  .join-form button {
    padding: 0px 10px;
    border: none;
    border-radius: 10px;
    background-color: #007aff;
    color: #fff;
    cursor: pointer;
    box-sizing: border-box; /* Include padding in height calculation */
    height: 42px; /* Explicit height to match input */
    font-size: 14px;
    width: 110px;
  }


  
