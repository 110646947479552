/* src/components/EventCard.css */
.event-card {
    background-color: #faf9f6; /* Blue background */
    color: #007aff; /* White text color for contrast */
    padding: 15px 20px;
    border-radius: 2px;
    margin-bottom: 20px; /* Space between cards if listed */
    height: 55vw; /* Responsive height based on viewport width */
  width: 55vw; /* Responsive width */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;/* Distributes space evenly */
    box-sizing: border-box; /* Makes sure padding is included in the width and height */
    
  }

  @media (min-width: 600px) {
    .event-card {
      height: 20vw; /* Adjust height for larger screens */
      width: 20vw; /* Adjust width for larger screens */
      max-height: 215px; /* Keep max dimensions to prevent the card from getting too large */
      max-width: 215px;
    }
  }
  
  .event-name {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: right;
    text-transform: lowercase; 
  }
  
  .event-date,
  .event-time {
    font-size: 20px;
    text-align: right;
    margin-bottom: 0px;
    
  }

  
  
  /* Ensure other elements inside the card adjust to the fixed dimensions */
  