/* Global styles.css or equivalent */

body, html {
  margin: 0;
  padding: 0;
  background-color: #0d0d0d; /* Dark background */
  color: #eeeeee; /* Light text */
  
 

  height: 100vh; /* Full viewport height */
  overflow: auto;
  width: 100%; /* Full width */
  position: absolute;
  top: 0;
  left: 0;
  
}

/* You can still have a .form-container class for other styling aspects of your forms */

textarea, input {
  font-size: 16px; /* Minimum size to prevent zoom on mobile */
}

@media only screen and (max-width:767px){
  html, body {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    scroll-behavior: smooth;
  }
}