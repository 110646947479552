.shareModal {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
  }
  
  .modalContent {
    background-color: #0d0d0d; /* White background for the modal content */
    border-radius: 8px; /* Rounded corners for the modal content */
    padding: 20px;
    box-shadow: 24px;
    position: relative;
    outline: none;
    width: 90%;
    max-width: 400px;
    height: 100%;
    overflow-y: auto; /* Enables vertical scrolling */
  }

  .modalHeader {
    background-color: #0d0d0d; /* Black background for the header */
    color: #fff; /* White text color */
    text-align: center;
    padding: 37px;
    border-top-left-radius: 8px; /* Match the border radius of the modal */
    border-top-right-radius: 8px; /* Match the border radius of the modal */
    font-size: 1.25rem; /* Or any other size you prefer */

  }
  
  .previewContainer { 
    border: .5px solid #faf9f681; /* Light grey border */
    padding: 16px;
    text-align: left;
    border-radius: 12px;
    color: #ffffff;
    width: 87%;
    align-items: center;
    margin: 0 auto; /* Auto margins for horizontal centering */
  flex-direction: column;
  margin-bottom: -30px;
  background-color: rgb(17, 17, 17);
  margin-top: -10px;
  box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.2), /* Outer white glow */
  0 0 5px 1px rgba(0, 72, 255, 0.5); /* Inner cyan glow for stronger effect */
  }

  
  .inputPreview {
    border: 1px solid grey;
    padding: 12px;
    margin-bottom: 10px;
    background-color: transparent;
    display: block;
    border-radius: 6px;
    margin-top: 15px;
  }
  
  .previewButton {
    padding: 5px 12px;
    background-color: #faf9f6; 
    border-radius: 4px;
    cursor: pointer;
    font-weight: 200;
    font-size: 1.3rem;
    color: #000000; /* Change button text to white */
    outline: none; /* Removes the default focus outline */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover and focus states */
    
  }

  .previewButton {
    margin-top: 10px;
    margin-left: auto; /* These two lines will center the button */
  margin-right: auto; /* These two lines will center the button */
  display: block; /* This will allow margins to take effect */
  }
  
  .buttonContainer {
    display: flex;
    justify-content: space-around; /* Space around the buttons */
    gap: 10px; /* Gap between buttons */
    margin-top: 50px;
    margin-bottom: 20px;
  }
  
  .buttonContainer > .modalButton {
    display: inline-block; /* Display as inline-block for text-align to work */
  }
  
  
  .modalButton {
    flex: 1; /* Grow equally */
    max-width: calc(50% - 10px); /* Maximum width, minus half of gap */
    padding: 15px 20px !important; /* Padding inside the buttons */
    border-radius: 12px !important; /* Rounded corners */
    font-size: 16px; /* Font size */
    margin: 0 !important; /* No margin */
    background-color: #60606082 !important; /* Light grey background */
    color: #ffffff; /* Darker text color for contrast */
    border: none; /* No border */
    display: flex; /* Use flexbox for centering */
    justify-content: center !important; /* Center horizontally */
    align-items: center !important; /* Center vertically */
    text-align: center !important; 
   
  }

  .modalButton {
    min-width: 100px; /* Minimum width of the buttons */
  }
  
  .copyLink {
    background-color: #60606082;
    color: #ffffff;
    
  }
  
  .shareButton {
    background-color: #60606082;
    color: #ffffff;
  }
  
  .closeButton {
    position: absolute;
    top: 30px; 
    left: 25px; 
    color: rgb(255, 255, 255); 
  }

  .previewTitle {
    color: #ffffff; /* This sets the title text to the blue color */
    text-align: left; /* Centers the title text */
    font-weight: bold;
  }

  .modalButton .MuiButton-startIcon {
    margin-right: 8px !important; /* Ensure proper spacing between icon and text */
  }
  
  /* Add min-width to the icon for consistent alignment */
  .modalButton .MuiSvgIcon-root {
    min-width: 24px !important; /* Width of the icon */
  }