/* AllEventsPage.css */
.events-container {
    padding: 20px;
    /* Light background for the whole page */
}

.events-title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem; /* Larger font size for mobile */
}

.events-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.event-item {
   
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px; /* Slightly rounded corners for the list items */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.event-link {
    text-decoration: none;
    font-size: 1.25rem; /* Larger text for links */
    font-weight: 500;
    display: block; /* Block display to make the entire area clickable */
}



